import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73911542"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_mobile_activator = _resolveComponent("tm-auto-size-panel-mobile-activator")!
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_calls_details_more_actions = _resolveComponent("calls-details-more-actions")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!
  const _component_details_table_item = _resolveComponent("details-table-item")!
  const _component_tm_list_chip_recipient = _resolveComponent("tm-list-chip-recipient")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_details_table = _resolveComponent("details-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    "bg-shade": "",
    breadcrumbs: _ctx.breadcrumbs,
    title: "Forwarded call details",
    size: "large"
  }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot", {}, undefined, true)
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right", {}, undefined, true)
    ]),
    "page-content-white": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_auto_size_panel, {
          "min-hidden-items": 2,
          "max-visible-items": 4
        }, {
          "dropdown-activator": _withCtx(({ visibleItemsCount }) => [
            _createVNode(_component_tm_auto_size_panel_mobile_activator, {
              "visible-items-count": visibleItemsCount,
              size: "large"
            }, null, 8, ["visible-items-count"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_tm_auto_size_panel_item_button, {
              icon: "chat",
              text: "View chat"
            }),
            _createVNode(_component_tm_auto_size_panel_item_button, {
              icon: "edit",
              text: "Edit forwarding settings"
            }),
            _createVNode(_component_tm_auto_size_panel_item_button, {
              icon: "tmi-download",
              text: "Download PDF"
            }),
            _createVNode(_component_calls_details_more_actions, { type: "forwarded" })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_details_table, null, {
        default: _withCtx(() => [
          _createVNode(_component_details_table_item, {
            label: "Call ID",
            value: "62471259"
          }),
          _createVNode(_component_details_table_item, {
            label: "Call time",
            value: "8 Jul 10:44 am"
          }),
          _createVNode(_component_details_table_item, { label: "Caller" }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_list_chip_recipient, { recipient: _ctx.recipient }, null, 8, ["recipient"])
            ]),
            _: 1
          }),
          _createVNode(_component_details_table_item, { label: "Number dialed" }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_country, { country: {
              id: 'us',
              name: '(207) 555-0119',
              counter: 'US Support',
            } }, null, 8, ["country"])
            ]),
            _: 1
          }),
          _createVNode(_component_details_table_item, {
            label: "Call forwarded to",
            value: "(302) 555-0107"
          }),
          _createVNode(_component_details_table_item, {
            label: "Greeting used",
            value: "None"
          }),
          _createVNode(_component_details_table_item, {
            label: "Price",
            value: "$0.083 / minute"
          }),
          _createVNode(_component_details_table_item, {
            label: "Call length",
            value: "00:00:15"
          }),
          _createVNode(_component_details_table_item, {
            label: "Total cost",
            value: "$0.12"
          })
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}